<template>
    <div>
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="backspace" />
                </a>
                <div></div>
            </div>
        </div>
        <section class="social_login social_wrapper">
            <div class="social_login_box">
                <div class="progress-step">
                    <ul class="progress-step-list">
                        <li class="current-item"><span>1</span></li>
                        <li><img src="/media/img/login/icon_agree_dot_color.svg" /></li>
                        <li><span class="social_vaild">2</span></li>
                    </ul>
                </div>

                <h2>본인 인증을 해주세요.</h2>
                <h3>가입을 위해 본인의 휴대폰 번호를 인증해 주세요.</h3>
                <button id="authbtn" v-if="!isCheckNice" class="join_certification2" @click="clickEvent()">본인 인증</button>
                <button id="authbtn" v-else class="join_certification2 done">
                    본인 인증
                    <span class="align_center">
                        <img class="done_img" src="/media/images/done.png" />
                    </span>
                </button>

                <div class="btn-area">
                    <button
                        id="social_button"
                        class="btn-next"
                        :disabled="!isCheckNice"
                        :class="{ check: isCheckNice }"
                        @click="saveUserInfo"
                    >
                        다음
                    </button>
                    <button style="visibility: hidden" disabled></button>
                </div>
            </div>
        </section>

        <input type="hidden" id="birthDate" value="" />
        <input type="hidden" id="encryptString" value="" />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import { getUserCheck, findOldSocialInfoUseMobileNo, updateUserAuth } from '@/service/api/niceApi';
export default defineComponent({
  props: ['backStatus'],

  data () {
    return {
      name: '',
      gender: '',
      mobileNo: '',
      birthDate: '',
      tokenVersionId: '',
      encData: '',
      integrityValue: '',
      userInfo: {},
      encryptString: '',
      isCheckNice: false
    };
  },

  mounted () {
    // if (this.backStatus !== 'Y') {
    // router.replace({ name: 'Login' });
    // }
    this.setInputEvent();
  },

  methods: {
    setInputEvent () {
      const input = document.querySelector('#birthDate');
      const encryptString = document.querySelector('#encryptString');
      input.addEventListener('change', $event => {
        if (encryptString.value && $event.target.value) {
          this.encryptString = encryptString.value;
          this.isCheckNice = true;
          encryptString.value = '';
        }
      });
    },
    makeFormData (tokenVersionId, encData, integrityValue) {
      const form = document.createElement('form');

      form.target = 'popup';
      form.method = 'Post';
      form.setAttribute('action', process.env.VUE_APP_NICE_URL);

      const inputService = document.createElement('input');
      inputService.setAttribute('type', 'hidden');
      inputService.setAttribute('value', 'service');
      inputService.setAttribute('id', 'm');
      inputService.setAttribute('name', 'm');

      form.appendChild(inputService);

      const inputToken = document.createElement('input');
      inputToken.setAttribute('type', 'hidden');
      inputToken.setAttribute('value', tokenVersionId);
      inputToken.setAttribute('id', 'token_version_id');
      inputToken.setAttribute('name', 'token_version_id');

      form.appendChild(inputToken);

      const inputEncData = document.createElement('input');
      inputEncData.setAttribute('type', 'hidden');
      inputEncData.setAttribute('value', encData);
      inputEncData.setAttribute('id', 'enc_data');
      inputEncData.setAttribute('name', 'enc_data');

      form.appendChild(inputEncData);

      const inputIv = document.createElement('input');
      inputIv.setAttribute('type', 'hidden');
      inputIv.setAttribute('value', integrityValue);
      inputIv.setAttribute('id', 'integrity_value');
      inputIv.setAttribute('name', 'integrity_value');

      form.appendChild(inputIv);

      document.body.appendChild(form);

      form.submit();

      document.body.removeChild(form);
    },
    async clickEvent () {
      window.open('', 'popup', 'width=545, height=875, toolbar=yes, location=no');
      getUserCheck()
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const { tokenVersionId, encData, integrityValue } = result;
            this.makeFormData(tokenVersionId, encData, integrityValue);
          }
        })
        .catch(error => {
          console.error('getUserCheck error  : ', error);
        });
    },

    saveUserInfo () {
      this.userInfo.userId = VueCookies.get('userId');
      this.getMailList();
    },

    getMailList () {
      findOldSocialInfoUseMobileNo(this.encryptString)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            if (result.length > 0) {
              this.$router.push({
                name: 'SocialIdCheck',
                params: {
                  encryptString: this.encryptString,
                  userInfo: this.userInfo,
                  mailList: result,
                  backStatus: 'Y'
                }
              });
            }
          } else {
            this.signUp();
          }
        })
        .catch(e => {
          console.log('e', e.response);
          if (e.response.data.resultCd === '400' && e.response.data.resultMsg === '조회된 데이터가 없습니다.') {
            this.signUp();
          }
        });
    },

    signUp () {
      const userId = this.userInfo.userId;

      updateUserAuth(this.encryptString, userId, '', '').then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          const { accessToken, refreshToken, comeFrom, userId: resUserId, email, userType } = result;
          this.$cookies.set('aToken', accessToken, '30d');
          this.$cookies.set('rToken', refreshToken, '30d');
          this.$cookies.set('comeFrom', comeFrom);
          this.$cookies.set('userId', resUserId);
          this.$cookies.set('email', email);
          this.$cookies.set('userType', userType);
          this.$gatag.event('sign_up', { method: comeFrom });
          // 메타 픽셀
          window.fbq('track', 'sign_up', {
            method: comeFrom
          });

          if (this.$cookies.get('autoLogin') === 'S') {
            this.$cookies.set('autoLogin', 'Y');
          }
          this.$router.push({ name: 'SocialFinished', params: { backStatus: 'Y' } });
        }
      });
    },

    backspace () {
      history.back();
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

button.join_certification2 {
  border: 1px solid rgba(255, 255, 255, 0.44);
  box-sizing: border-box;
  width: 100%;
  padding: 12px 0;
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  border-radius: 8px;
  text-align: center;
  margin: 0px 0px 32px;
  line-height: 1.2;
}

.join_certification2.done {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: initial;
}

.done_img {
  width: 13px;
  height: 10px;
  margin-left: 7px;
  position: relative;
  left: 0;
  transform: translate(0, 0);
}

.login_main_box button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#social_button {
  opacity: 0.3;
}

#social_button.check {
  opacity: 1;
}

@media all and (max-width: 500px) {
  button.join_certification {
    font-size: 15px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/social.css"></style>
<style src="@/assets/css/font.css"></style>
